import {
  CLUSTER_SETTINGS_DATA,
  FETCHING_CLUSTER_SETTINGS_DATA,
  SET_STUDENT_CLUSTER_ANALYZER_LOADING,
  SET_STUDENT_CLUSTER_ANALYZER_STATUS,
} from '../../constant/actionTypes';

import service from "../../utils/customServices/dashboardManagement";

const setClusterSettingLoading = payload => dispatch => {
  dispatch({
    type: SET_STUDENT_CLUSTER_ANALYZER_LOADING,
    payload,
  });
};

const setClusterSettingStatus = payload => dispatch => {
  const data = {
    cluster: {
      cluster_enable: payload.clusterAnalyserEnable, 
      cluster_analyzer_enable: payload.assistantEnable
    }
  }

  dispatch({
    type: CLUSTER_SETTINGS_DATA,
    payload: {
      isLoading: false,
      error: null,
      data
    },
  });
}

export const getClusterSettingStatus = () => dispatch => {
  dispatch(
    setClusterSettingLoading(true),
  );
};

export const saveClusterSettingStatus = (data) => dispatch => {
  dispatch(
    setClusterSettingLoading(true),
  );

  service.make({
    service: "insights/settings/global/clusterSettingSave",
    method: "POST",
    authorized: true,
    data,
  }).then(response => {
    dispatch(setClusterSettingStatus(data))
  }).catch(error => {
 //   dispatch(setClusterSettingStatus(data))
  })
};

const fetchingClusterSettingData = (isLoading = true) => dispatch => {
  dispatch({
    type: FETCHING_CLUSTER_SETTINGS_DATA,
    payload: {isLoading},
  });
}

const clusterSettingData = (response) => dispatch => {
  const data = {
    cluster: {...response.data }
  }
  dispatch({
    type: CLUSTER_SETTINGS_DATA,
    payload: {
      isLoading: false,
      error: response.error,
      data
    },
  });
}

export const fetchSettingData = (data) => dispatch => {
  dispatch(
    fetchingClusterSettingData(true),
  );

  service.make({
    service: "insights/settings/global/clusterSettingFetch",
    method: "POST",
    authorized: true,
    data,
  }).then(response => {
    dispatch(clusterSettingData(response));
  }).catch(error => {
    dispatch(clusterSettingData(error));
  })
};
