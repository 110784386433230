import React, { useCallback, useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { getAccountBaseUser, validateSelectedUser } from "../../../../actions/userManagement/userManagement.action";
import { getIsUsersLoading, getSearchedUsersList, getValidateUserData, getValidateUserErrors, getValidateUserLoadingState } from "../../../../selectors/userManagement/userManagement.selector";
import _debounce from "lodash/debounce";
import { get as _get } from 'lodash';
import { dashboardConst } from "../../../../constant/dashboardManagement/commonDashboardConst";
import PopUpModal from "../../../common/popup-modal/popup-modal";
import { LoadingOutlined } from '@ant-design/icons';

const UserSearch = ({
  selectedAccount,
  selectedUsers,
  setSelectedUsers,
  isModalVisible,
  setValidationModalOpen,
  validationModalOpen,
}) => {
  const dispatch = useDispatch();

  const searchedUsersList = useSelector(getSearchedUsersList);
  const isUsersLoading = useSelector(getIsUsersLoading);
  const isUserValidating = useSelector(getValidateUserLoadingState);
  const validatedUserData = useSelector(getValidateUserData);
  const validatedUserError = useSelector(getValidateUserErrors);

  const [searchUserText, setSearchUserText] = useState('');
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    if (!isUserValidating) {
      const validatedUserResponse = _get(validatedUserData, "data", 0);

      if (!!validatedUserResponse) {
        setValidationModalOpen(true);
      }
      if (validatedUserResponse === 0 && !Object.keys(validatedUserError).length) {
        updateUsersList();
      }
    }
  }, [validatedUserData, isUserValidating]);

  const onSubmit = () => {
    updateUsersList();
  };

  const onCancel = () => {
    setSelectedUser({});
  };

  const updateUsersList = () => {
    const userAlreadySelected = selectedUsers.filter(user => user.userId === selectedUser.userId);
    if (!userAlreadySelected.length && Object.keys(selectedUser).length) {
      const newUserData = {
        ...selectedUser,
        accountId: selectedAccount.accountId,
        accountType: selectedAccount.accountRole,
      };
      selectedUser.userId && setSelectedUsers([...selectedUsers, newUserData]);
    }
    setSelectedUser({});
  };

  const onUserSelect = async (_, data) => {
    setSearchUserText("");
    const { value, label, ...userData } = data;
    setSelectedUser(userData);

    const userAlreadySelected = selectedUsers.filter(user => user.userId === userData.userId);
    if (!userAlreadySelected.length) {
      dispatch(validateSelectedUser({
        searchValue: userData.userId,
        accountId: selectedAccount.accountId,
      }));
    }
  };

  const searchedUsersArray = () => {
    if (!isUsersLoading && (!searchedUsersList.length || searchUserText.length <= 2)) {
      return [];
    }

    if (searchUserText.length > dashboardConst.minSearchTextLength && !isUsersLoading) {
      return searchedUsersList.map(users => {
        return {
          value: users.name,
          ...users
        }
      })
    }
  }

  // init debounced calls
  const debouncedCall = (value, callback) => {
    setSearchUserText(value);
    callback({ value, accountSelected: selectedAccount });
  };

  const onUserSearch = useCallback(_debounce(({ value, accountSelected }) => {
    if (value.length > dashboardConst.minSearchTextLength) {
      const payload = {
        searchValue: value,
        accountId: accountSelected.accountId
      }
      dispatch(getAccountBaseUser(payload))
      return;
    }

    return;
  }, 500), [JSON.stringify(searchedUsersList)]);

  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
      }}
    />)

  const renderNoData = () => {
    if (searchUserText.length <= 2 || isUsersLoading) {
      return null;
    }
  }

  const renderContent = () => {
    const userExist = _get(validatedUserData, "existUser", false);
    if (userExist) {
      return (
        <div>
          User already exist.
        </div>
      )
    }
    return (
      <div>
        Selected user currently acting as a student and an observer in Canvas.
        Are you sure you want to add this user as an account admin in tentacle?
      </div>
    )
  };

  return (
    <>
      {isModalVisible &&
        <Spin
          spinning={isUserValidating}
          indicator={<LoadingOutlined />}
        >
          <Select
            showSearch
            placeholder="Search by username or user ID"
            onSelect={onUserSelect}
            onSearch={value => debouncedCall(value, onUserSearch)}
            suffixIcon={suffix}
            value={[]}
            options={searchedUsersArray()}
            notFoundContent={renderNoData()}
          />
          <PopUpModal
            title={"Are you sure"}
            okText={"Yes"}
            onPressOk={onSubmit}
            cancelText={"No"}
            setIsModalVisible={setValidationModalOpen}
            isModalVisible={validationModalOpen}
            onCancel={onCancel}
          >
            {renderContent()}
          </PopUpModal>
        </Spin>
      }
    </>
  )
}

export default UserSearch;
