import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import CustomAvatar from "./CustomAvatar";

const DetailModal = props => {
  const {
    selectedNode,
    setModalStatus,
    setSelectedNode,
    modalStatus,
    selectedNodeColor,
    setSelectedNodeColor,
  } = props;

  const { users, value } = selectedNode;

  const onCloseModal = () => {
    setSelectedNode([]);
    setModalStatus(false);
    setSelectedNodeColor('');
  };

  const performanceDetailCard = (performance, engagement) => {
    return (
      <div
        className="card-section"
        style={{ backgroundColor: selectedNodeColor }}
      >
        <Row className="card-row" gutter={16}>
          <Col span={12}>
              <div className="card-item">
                <div className="card-text">
                  <span className="card-text-title">
                    Performance
                  </span>
                  <span className="card-text-number">
                    {performance}
                  </span>
                </div>
              </div>
          </Col>
          <Col  span={12}>
            <div className="card-item">
              <div className="card-text">
                <span className="card-text-title">
                  Engagement
                </span>
                <span className="card-text-number">
                  {engagement}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const userDetailsCard = (user) => {
    return (
      <div className="userCardCluster">
        <CustomAvatar list={[user]} color={selectedNodeColor} size={44} />

        <div className="name-field-container">
          <div className="name-field">{user.name}</div>
          <div className="id-field">{user.id}</div>
        </div>
      </div>
    )
  }

  const renderModalContent = () => {
    if (!users) return null;

    if (users.length === 1) {
      const user = users[0];

      return (
        <div className="cluster-chart-modal-singal">
          {performanceDetailCard(value.performance, value.engagement)}
          <div
            className="userLists"
          >
            <div>
              {userDetailsCard(user)}
            </div>
          </div>
          {/* Will be added when ChatGPT is enabled */}
          {/* <div className="description-section">
            {details.name} is a dedicated and hardworking student who consistently strives
            for academic excellence.
          </div>
          <Button className="modal-button" icon={<EyeOutlined />}>
            View details
          </Button> */}
        </div>
      )
    }

    return (
      <div className="cluster-chart-modal-singal ">
        {performanceDetailCard(value.performance, value.engagement)}
        <div className="userLists">
          {
            users.map((user, index) => {
              const { name, id } = user;
              const key = id || `${name}_${index}`
              return (
                <div
                  key={key}
                >
                  {userDetailsCard(user)}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  return (
    <Modal
      title='Student Analyzing Summary'
      footer={null}
      onCancel={onCloseModal}
      visible={modalStatus}
      width={450}
      className="clusterModal"
    >
      {renderModalContent()}
    </Modal>
  )
};

export default DetailModal;