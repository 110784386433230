import { get as _get } from 'lodash';
import {
    FETCH_ASSIGNMENTS,
    FETCH_ASSIGNMENTS_LOADING,
    FETCH_ASSIGNMENTS_INFORMATION,
    FETCH_ASSIGNMENTS_INFORMATION_LOADING,
    FETCHING_ASSIGNMENT_FILTER_DATA,
    FETCHED_ASSIGNMENT_FILTER_DATA,
    FETCH_STUDENT_ASSIGNMENTS_CHART_DATA,
    CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA,
} from "../../constant/actionTypes";
import request from "../../utils/request";
import {getAccessToken} from "../../utils/general";

// Fetch all
// handle fetch loading state for assignments fetch request
export const fetchingAssignments = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_ASSIGNMENTS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the assignments fetch request
export const setAssignmentsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: FETCH_ASSIGNMENTS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle assignments fetch request
export const fetchAssignments = (payload) => dispatch => {
    dispatch(fetchingAssignments());

    const feedReducer = (response) => {
        dispatch(setAssignmentsFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/assignment_profile/assignments_details/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

/*** cohort and gauge data fetch ***/
// clear last fetched chart data before new fetch
export const clearAssignmentChartData = () => dispatch => {
    dispatch({
        type: CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA,
    });
};

// handle response of the student cohort fetch request
export const setTeacherProfileAssignmentChartDataFetchStatus = response => dispatch => {
    const data = _get(response, 'response.data.result.data');

    if (data) {
        dispatch({
            type: FETCH_STUDENT_ASSIGNMENTS_CHART_DATA,
            payload: data,
        });
    }
};

// handle teacher profile student assignments cohorts data
export const fetchTeacherStudentAssignmentsChartData = data => dispatch => {
    const feedReducer = response => {
        dispatch(setTeacherProfileAssignmentChartDataFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/assignment_profile/assignmentCohortData/${getAccessToken()}`,
        method: "POST",
        data,
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

export const fetchAssignmentFilterData = payload => dispatch => {
  dispatch(setAssignmentFilterDataStatus());

  const feedReducer = response => {
    dispatch(setAssignmentFilterData(response));
  };

  const requestOptions = {
    service: `insights/assignment_profile/assignmentFilterData/${getAccessToken()}`,
    method: "POST",
    data: {...payload.requestParams},
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

export const setAssignmentFilterData = (data, type) => dispatch => {
  const { isLoading, error, response }  = data;
    dispatch({
      type: FETCHED_ASSIGNMENT_FILTER_DATA,
      payload: {
        isLoading,
        error,
        data: _get(response, 'data.data', {}),
      }
  });
}

export const setAssignmentFilterDataStatus = (payload = true) => dispatch => {
  dispatch({
    type: FETCHING_ASSIGNMENT_FILTER_DATA,
    payload,
  });
};

// handle fetch loading state for assignments Information fetch request
export const fetchingAssignmentsInformation = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_ASSIGNMENTS_INFORMATION_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the assignments Information fetch request
export const setAssignmentsInformationFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: FETCH_ASSIGNMENTS_INFORMATION,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle assignments Information fetch request
export const fetchAssignmentsInformation = (payload) => dispatch => {
    dispatch(fetchingAssignmentsInformation());

    const feedReducer = (response) => {
        dispatch(setAssignmentsInformationFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/assignment_profile/assignment_information/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};