import React from "react";
import { Avatar, Popover } from "antd";

const maxCount = 10;

const CustomAvatar = ({ list, color, size, userName }) => {

  if (list.length === 1) {
    const item = list[0];
    return <Avatar size={size} src={item.avatar} />
  }

  const content = (
    <div>
       { list.length > 1 ? `Multiple Students` : `${userName}`}
    </div>
  );

  return (
    <Popover content={content}>
      <Avatar
        size={size}
        style={{
          backgroundColor: `${color}99`,
          color: '#000000',
          fontWeight: 600,
        }}
      >
        {list.length > maxCount ? `+ ${maxCount}` : `+ ${list.length - 1}`}
      </Avatar>
    </Popover>
  )
};


CustomAvatar.defaultProps = {
  size: 39,
};

export default CustomAvatar;