import _get from "lodash/get";
import { createSelector } from "reselect";

// return innovative term response
export const makeInnovativeTermsResponse = state => _get(state, 'innovative.course.terms');

export const makeInnovativeAdminStatusResponse = state => _get(state, 'innovative.course.adminStatus');

export const makeInnovativeCourse= state => _get(state, 'innovative.course');

export const makeInnovativeCourseStatus  = createSelector(
  makeInnovativeCourse, data => _get(data, 'courseFilters'),
);

// return selected term
export const getSelectedTerm = state => {
  const result = makeInnovativeTermsResponse(state);
  return result && result.selected;
};

// Get whether the user is admin user
export const getAdminStatus = state => {
  const result = makeInnovativeAdminStatusResponse(state);
  return _get(result, 'response.isAdmin');
}

export const getAdminAccounts = state => {
  const result = makeInnovativeAdminStatusResponse(state);
  return _get(result, 'response.accounts');
}

// Admin Verification is Progress Loading status
export const adminVerificationLoading = state => {
  const result = makeInnovativeAdminStatusResponse(state);
  return result && result.isLoading;
}

// get Selected Account
export const getSelectedAccount =  createSelector(
  makeInnovativeAdminStatusResponse, data => _get(data, 'selected'),
);

// return innovative term response loading status
export const makeInnovativeTermsLoading = state => {
  const result = makeInnovativeTermsResponse(state);
  return result && result.isLoading;
};

// return innovative term response data
export const makeInnovativeTermsResponseData = state => {
  const result = makeInnovativeTermsResponse(state);
  return _get(result, 'response.data');
};

// return innovative course response
export const makeInnovativeCourseResponse = state => _get(state, 'innovative.course.selected');

// return innovative course response loading status
export const makeInnovativeCourseLoading = state => {
  const result = makeInnovativeCourseResponse(state);
  return result && result.isLoading;
};

// return innovative course response data
export const makeInnovativeCourseResponseData = state => {
  const result = makeInnovativeCourseResponse(state);
  return _get(result, 'response.data.result');
};

// return innovative course response error
export const makeInnovativeCourseError = state => {
  const result = makeInnovativeCourseResponse(state);
  return result.error;
};


// return innovative courses response
export const makeInnovativeCoursesResponse = state => _get(state, 'innovative.course.list');

// return innovative courses response loading status
export const makeInnovativeCoursesLoading = state => {
  const result = makeInnovativeCoursesResponse(state);
  return result && result.isLoading;
};

const returnCourses = (data) => {
  if (data?.response?.data?.result) {
    return _get(data, 'response.data.result', []);
  }
  return _get(data, 'response.data.data', []);
};

// return innovative courses response data
export const makeInnovativeCoursesResponseData = createSelector(
  makeInnovativeCoursesResponse, data => returnCourses(data),
);

export const makeInnovativeCoursesPaginationData = state => {
  const result = makeInnovativeCoursesResponse(state);
  return _get(result, 'response.data.pagination');
};

// return innovative courses response error
export const makeInnovativeCoursesError = state => {
  const result = makeInnovativeCoursesResponse(state);
  return result.error;
};



// return innovative courses main report response
export const makeInnovativeCourseMainReportResponse = state => _get(state, 'innovative.course.mainReport');

// return innovative courses main report response loading status
export const makeInnovativeCoursesMainReportLoading = createSelector(
  makeInnovativeCourseMainReportResponse, data => _get(data, 'isLoading', false),
);

// return innovative courses main report student response data
export const makeInnovativeCourseMainReportsResponseData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result', {});
};

// return innovative courses main report student response data
export const makeInnovativeCourseMainReportStudentsResponseData = createSelector(
  makeInnovativeCourseMainReportResponse, data => _get(data, 'response.data.result.student_details', []),
);

// return innovative courses main report summaries response data
export const makeInnovativeCoursesMainReportSummariesResponseData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result.main_report_cards', []);
};

// return innovative courses main report risk_indicator response data
export const makeInnovativeCoursesMainReportRiskIndicatorResponseData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result.risk_indicator', []);
};

// return innovative courses main report response error
export const makeInnovativeCoursesMainReportError = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return result.error;
};


// return main report outcome details data
export const makeInnovativeCoursesMainReportOutcomeData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result.outcomes', []);
};

// return main report outcome list data
export const makeInnovativeCoursesMainReportOutcomeListData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result.outcomeColumns', []);
};

export const makeInnovativeCouserSwitchValues = state => (
  _get(state, 'innovative.course.switchFormValues', {})
);

export const makeModuleProgress = createSelector(
  makeInnovativeCourse, data => _get(data, 'moduleProgressDataArray', []),
);

export const getModuleProgressItemsObj = createSelector(
  makeInnovativeCourse, data => _get(data, 'moduleProgressItemsObj', {}),
);

//get selected course filter dates
export const getfilterDateForFetchCourse = createSelector(
  makeInnovativeCourse, data => _get(data, 'fetchCourseDateFilters'),
);

//get application admin data
export const getIsApplicationAdmin = createSelector(
  makeInnovativeCourse, data => _get(data, 'applicationAdmin', {}),
);

//get Cluster data
export const makeClusterData = state => _get(state, 'innovative.course.clusterData', {});

//get cluster data Error
export const makeClusterDataError = createSelector(
  makeClusterData, data => _get(data, 'error', false),
);

//get cluster data
export const getStudentClusterData = state => {
  const result = makeClusterData(state);
 return _get(result, 'response.data', []);
};

//get loading state from cluster request
export const getStudentClusterDataLoading = state => {
  const result = makeClusterData(state);
  return _get(result, 'isLoading', false);
};

// return innovative courses main report clusterStatus response data
export const makeInnovativeCoursesMainReportClusterStatusResponseData = state => {
  const result = makeInnovativeCourseMainReportResponse(state);
  return _get(result, 'response.data.result.clusterStatus', {});
};

export const makeMessageData = state => _get(state, 'innovative.course.messageData', {});

//get message data
export const getChatMessages = state => {
  const result = makeMessageData(state);
  return _get(result, 'response.data.historyMessages', []);
};

// get chat retry seconds
export const getRetryChatAfter =  createSelector(
  makeMessageData, data => _get(data, 'response.data.retryAfter'),
);

// get courseId related to chat
export const getChatCourseId =  createSelector(
  makeMessageData, data => _get(data, 'response.data.courseId'),
);

//get message isloading
export const getIsChatMessagesLoading = state => {
  const result = makeMessageData(state);
  return _get(result, 'isLoading', false);
};

//get message data
export const getChatMessagesErrors = state => {
  const result = makeMessageData(state);
  return _get(result, 'error', {});
};

export const makeSendMessageData = state => _get(state, 'innovative.course.chatMessageSending', {});

//get message sending status
export const getIsMessageSending = state => {
  const result = makeSendMessageData(state);
  return _get(result, 'isLoading', false);
};

//get Selected filter radio value
export const getSelectedFilterRadioValue = state => _get(state, 'innovative.course.selectedRadioFilterValue', "");