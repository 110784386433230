import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchClusterData } from "../../actions/innovative/course.action";
import session from "../session";

export const useWebSocket = ({ serverUrl, protocols, onMessageHandler, onOpenHandler, onConnectionCloseHandler, initSendPayload }) => {
  const dispatch = useDispatch();

  const [isConnectionOpen, setIsConnectionOpen] = useState(false);

  let socketConnection;
  let connectionProps;

  const send = (payload) => {
    socketConnection.send(
      JSON.stringify(payload)
    );
  };

  const close = () => {
    if (isConnectionOpen) {
      socketConnection.close();
      onConnectionCloseHandler && onConnectionCloseHandler();
    }
  };

  const createConnection = (props) => {
    connectionProps = props;

    const additionalPayload = props;
    socketConnection = new WebSocket(serverUrl, protocols);

    socketConnection.onmessage = function (event) {
      const eventData = JSON.parse(event.data);
      onMessageHandler && onMessageHandler(eventData);

      switch (eventData.platform) {
        case "canvas": {
          switch (eventData.event_name) {
            case "student-clustering": {
              dispatch(fetchClusterData({
                "user_id": session.get([
                  session.keys.innovativeMeta,
                  "token"
                ]),
              }
              ))
              return
            }
            default: {
              return
            }
          }
        }
        default: {
          return
        }
      }
    };

    socketConnection.onopen = function (event) {
      setIsConnectionOpen(true);
      initSendPayload && send({ ...initSendPayload, ...additionalPayload })
      onOpenHandler && onOpenHandler(event);
    };

    socketConnection.onclose = function (event) {
      setIsConnectionOpen(false);
      createConnection(connectionProps);
    };
  };

  return {
    close,
    send,
    isConnectionOpen,
    createConnection,
  };
};
