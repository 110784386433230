import React, { useEffect, useState } from "react";
import { Tag, Form } from "antd";
import { useDispatch } from "react-redux";
import PopUpModal from "../../../common/popup-modal/popup-modal";
import {
  onAddUserSubmit,
  searchedUserData,
  validateUserLoading,
} from "../../../../actions/userManagement/userManagement.action";
import UserSearch from "./userSearch";
import SelectAccount from "./selectAccount";

const AddUserPopUpModal = ({ isModalVisible, setIsModalVisible }) => {
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [validationModalOpen, setValidationModalOpen] = useState(false);

  useEffect(() => {
     isModalVisible && dispatch(validateUserLoading(false)); 
  }, [isModalVisible]);

  const emptySearchData = () => {
    setValidationModalOpen(false);
    dispatch(searchedUserData({
      response: {
        data: {
          data: [],
        },
      }
    }));
    setSelectedUsers([]);
  }

  const onModalSubmit = () => {
    if (selectedUsers.length) {
      dispatch(onAddUserSubmit({ selectedUsers }));
    }
    emptySearchData();
  };

  const onDeselectUserTag = removedUser => {
    const removedSelectedUserArray = selectedUsers.filter(user =>
      user.userId !== removedUser.userId);
    setSelectedUsers(removedSelectedUserArray);
  };

  const renderTag = data => {
    if (data.sis_id) {
      return `${data.name}(${data.sis_id})`
    }

    return data.name;
  };

  return (
    <PopUpModal
      title={"Add Users"}
      okText={"Add"}
      onPressOk={onModalSubmit}
      setIsModalVisible={setIsModalVisible}
      isModalVisible={isModalVisible}
      okButtonDisabled={!selectedUsers.length}
      onCancel={emptySearchData}
    >
      <div className="addUserPopup">
        <Form.Item label="Select Account" >
          <SelectAccount
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </Form.Item>
        <Form.Item label="Select Users" >
          <UserSearch
            selectedAccount={selectedAccount}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            isModalVisible={isModalVisible}
            validationModalOpen={validationModalOpen}
            setValidationModalOpen={setValidationModalOpen}
          />
          <div className="tagList">
            {selectedUsers && selectedUsers.map(data => (
              <Tag
                key={data.userId}
                closable
                onClose={e => { onDeselectUserTag(data) }}>
                {renderTag(data)}
              </Tag>
            )
            )}
          </div>
        </Form.Item>
      </div>
    </PopUpModal >
  )
}

export default AddUserPopUpModal;
