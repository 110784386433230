import React, { useEffect, useState, memo } from "react";
import { Row, Col, Radio, Form } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
import AccountFilter from "./sidebarPartials/AccountFilter";
import {
  adminVerificationLoading,
  getAdminAccounts,
  getSelectedAccount,
  makeInnovativeCoursesLoading,
  makeInnovativeCoursesMainReportLoading,
  makeInnovativeCourseStatus,
  getfilterDateForFetchCourse,
  getIsApplicationAdmin,
  getStudentClusterDataLoading,
  getSelectedTerm,
  makeInnovativeTermsResponseData,
  makeInnovativeTermsLoading
} from "../../../../selectors/innovative/course.selector";
import {
  clearCourseData,
  fetchCourses,
  setCourseStatus,
  setSelectedAccount,
  setTerm,
  updateApplicationLevelAdmin,
  updateFilterRadioValue
} from "../../../../actions/innovative/course.action";
import session from "../../../../utils/session";
import CourseStatusFilter from "./sidebarPartials/CourseStatus";
import CourseDateFilter from "../courseDateFilter/courseDateFilter.js";
import TermFilter from "../termFilter/index.jsx";

const ADMIN_PATH = "/insights/admin/courses";
const RADIO_PERIOD = "PERIOD";
const RADIO_STATUS = "STATUS";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const CustomHeaderBar = () => {

  const accountList = useSelector(getAdminAccounts);
  const accountsLoading = useSelector(adminVerificationLoading);
  const selectedAccount = useSelector(getSelectedAccount);
  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const areCoursesLoading = useSelector(makeInnovativeCoursesLoading);
  const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const selectedDateFilter = useSelector(getfilterDateForFetchCourse);
  const selectedState = useSelector(makeInnovativeCourseStatus);
  const selectedTermId = useSelector(getSelectedTerm);
  const terms = useSelector(makeInnovativeTermsResponseData);
  const termsLoading = useSelector(makeInnovativeTermsLoading);

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const isAdminPath = path === ADMIN_PATH;
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManagementData, "is_admin", false);

  const isAdminDashboardView = () => {
    return (path === ADMIN_PATH && (isApplicationAdminData.role.toLowerCase() === ACCOUNT_MEMBERSHIP || actAs.toLowerCase() === ACCOUNT_MEMBERSHIP));
  };

  const isAdminDashboard = isAdminDashboardView();

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  }

  const dispatch = useDispatch();
  const [isDateFilterVisible, setDateFilterVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("");

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs,
    }));
  }, [actAs]);

  const handleAccountsChange = accountId => {
    dispatch(setSelectedAccount(accountId));
    dispatch(fetchCourses({
      existingRequest: false,
      requestParams: {
        "user_id": session.get([
          session.keys.innovativeMeta,
          "token"
        ]),
        accountId,
        filterCourseStates: selectedState,
        selectedToggle: radioValue === RADIO_PERIOD ? "period" : "",
        ...selectedDateFilter,
        ...adminPayload
      }
    }))
  }

  useEffect(() => {
    if (radioValue === RADIO_STATUS) {
      if (selectedAccount) {
        dispatch(fetchCourses({
          existingRequest: false,
          requestParams: {
            "user_id": session.get([
              session.keys.innovativeMeta,
              "token"
            ]),
            accountId: selectedAccount,
            filterCourseStates: selectedState,
            selectedToggle: radioValue === RADIO_PERIOD ? "period" : "",
            ...selectedDateFilter,
            ...adminPayload
          }
        }))
      }
    }
  }, [radioValue]);

  const handleOnFilterApply = (statusList) => {
    dispatch(clearCourseData());
    dispatch(setCourseStatus(statusList));
  }

  const onChange = e => {
    const value = _get(e, 'target.value', RADIO_STATUS);
    setRadioValue(value);
    dispatch(updateFilterRadioValue(value));
    setDateFilterVisible(value === RADIO_PERIOD);
  };

  // term filter change: re-fetch course data
  const termChange = (e) => {
    dispatch(clearCourseData())
    dispatch(setTerm(e));
    dispatch(fetchCourses({
      existingRequest: true,
      requestParams: {
        "user_id": session.get([
          session.keys.innovativeMeta,
          "token"
        ]),
        accountId: selectedAccount,
        term_ids: [e],
        filterCourseStates: selectedState,
        selectedToggle: radioValue === "PERIOD" ? "period" : "",
        ...selectedDateFilter,
        ...adminPayload
      }
    }))
  }

  const className = isClusterDataLoading ? "in-sectionCover-disabled" : "in-sectionCover";

  return (
    <div className={className}>
      <div className={"custom-filter-header"}>
        <Row gutter={15}>
          {isAdminDashboard && (
            <Col
              flex="181px"
              className="header-radio-col"
            >
              <Form.Item
                colon={false}
                className="header-radio-button-group"
              >
                <Radio.Group
                  onChange={onChange}
                  defaultValue={RADIO_STATUS}
                >
                  <Radio value={RADIO_STATUS}>Status</Radio>
                  <Radio value={RADIO_PERIOD}>Period</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
          <Col flex="auto">
            <div className={isAdminPath ? "in-container" : "non-admin-header-row-container"}>
              <Row>
                {isAdminDashboard && isDateFilterVisible && (
                  <Col span={8} className="header-col">
                    <Form.Item
                      colon={false}
                    >
                      <CourseDateFilter />
                    </Form.Item>
                  </Col>
                )}
                {!isDateFilterVisible && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Course Status"
                    >
                      <CourseStatusFilter
                        onFilterApply={handleOnFilterApply}
                        selectedState={selectedState}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isAdminDashboard && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Term"
                    >
                      <TermFilter
                        terms={terms}
                        termsLoading={termsLoading}
                        reportLoading={areCoursesLoading || courseMainReportLoading}
                        termChange={termChange}
                        selectedTerm={selectedTermId}
                        wrapperId="innovative-term-parent-select"
                      />
                    </Form.Item>
                  </Col>
                )}
                {isAdminDashboard && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Accounts"
                    >
                      <AccountFilter
                        accounts={accountList}
                        accountChange={handleAccountsChange}
                        accountsLoading={accountsLoading}
                        reportLoading={areCoursesLoading || courseMainReportLoading}
                        selectedAccount={selectedAccount}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default memo(CustomHeaderBar);
