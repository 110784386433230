import React, { memo, useEffect, useRef } from "react";
import { Avatar, Card, Col, Row } from "antd";
import agentIcon from "../../../../assets/images/chatGPT/agent-iocn.png";

const ChatCard = ({ chatData, align, scrollToEndOfChatRef }) => {
  const cardRef = useRef()

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      scrollToEndOfChatRef && scrollToEndOfChatRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    });
    resizeObserver.observe(cardRef.current);
    return () => resizeObserver.disconnect();
  }, [])

  const renderText = () => {

    if (align === "left") {
      return (<Row justify="start">
        {chatData.content
          .split(/<br ?\/?>/)
          .flatMap((line, i) => [line, <br key={`line-${i}`} />])}
          
        {/* {chatData.content?.length && chatData.content.map((sentence, index) => (
          sentence.length === 0 ?  '\n' :
          <p key={`${index}-sentence`}>{sentence} {'\n'}</p>
        ))} */}
      </Row>)
    }
    return (
      <Row justify="end">
        {chatData.content}
      </Row>
    )
  }

  return (
    <div className={align === "left" ? "assistant-obi" : "user-obi"}>
      <Card className="chatThread">
        <Col justify={align === "left" ? "start" : "end"}>
          {
            align && (
              <div className="botAvetar">
                <Avatar size={"small"} src={agentIcon} />
                <div>
                  <b>Octopus AI</b>
                </div>
                <div className="betaChip">
                  Beta
                </div>
              </div>
            )
          }
          <div ref={cardRef}>
            {renderText()}
          </div>
        </Col>
      </Card>
    </div>

  )
}

export default memo(ChatCard);
