const getXLines = (middleColor = '#d9d9d9' ) => (
  [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(line => {
    return { 
      coordinates: [
        {engagement: 0, performance: line},
        {engagement: 100, performance: line}
      ],
      width: line === 50 ? 2 : 0.5,
      color: line === 50 ? middleColor : '#e6e6e6',
    }
  })
);

export const plotLines = [
  ...getXLines(),
  { 
    coordinates: [
      {engagement: 50, performance: 0},
      {engagement: 50, performance: 100}
    ],
    width: 2,
    color: '#d9d9d9'
  },
]

export const scatterPlotLines = [
  ...getXLines('#333333'),
  { 
    coordinates: [
      {engagement: 50, performance: 0},
      {engagement: 50, performance: 100}
    ],
    width: 2,
    color: '#333333'
  },
]
