import {
  CLEAR_TERMS_DATA,
  CLEAR_COURSE,
  FETCH_COURSE,
  FETCH_COURSE_LOADING,
  FETCH_COURSE_REPORT,
  FETCH_COURSE_REPORT_LOADING,
  FETCH_COURSES,
  FETCH_COURSES_LOADING,
  FETCH_TERMS,
  FETCH_TERMS_LOADING,
  SET_TERM,
  FETCH_IS_ADMIN_LEVEL_USER_LOADING,
  FETCH_IS_ADMIN_LEVEL_USER,
  SET_SELECTED_ACCOUNT,
  CLEAR_COURSE_REPORT,
  SET_COURSE_FILTER,
  SET_SWITCH_FORM_VALUES,
  FETCH_COURSE_PROGRESS,
  FETCH_STUDENT_CLUSTER_DATA,
  FETCH_STUDENT_CLUSTER_DATA_LOADING,
  FETCH_CHAT_HISTORY_DATA,
  FETCH_CHAT_HISTORY_DATA_LOADING,
  SEND_CHAT_MESSAGE_LOADING,
  UPDATE_SEND_CHAT,
  RESET_ADMIN_LEVEL_DATA,
  CLEAR_COURSES,
  UPDATE_APPLICATION_LEVEL_ADMIN_USER,
  FETCH_CHAT_HISTORY_ERROR_HANDEL,
  FETCH_MODULE_ITEM_DATA_LOADING,
  FETCH_MODULE_ITEM_DATA,
  RESET_MODULE_PROGRESS_DATA,
  RESET_STUDENT_CLASSIFICATION_DATA,
  RESET_CHAT_COUNT_DOWN_TIMER,
  UPDATE_FILTER_RADIO_VALUE,
} from "../../constant/actionTypes";
import _get from "lodash/get";

const courseReducerDefaultState = {
  selected: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {},
      },
    }
  },
  list: {
    isLoading: false,
    error: false,
    response: {
      data: {
        data: [],
      },
    }
  },
  mainReport: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {
          student_details: [],
          outcome_details: [],
          outcome_list: [],
          main_report_cards: [],
          risk_indicator: [],
          clusterStatus: {},
        },
      },
    }
  },
  adminStatus: {
    isLoading: false,
    response: {
      isAdmin: ""
    },
    selected: ""
  },
  applicationAdmin: {
    is_admin: "",
    role: ""
  },
  courseFilters: ["all"],
  terms: {
    selected: false,
    isLoading: false,
    error: false,
    response: {
      data: [],
    }
  },
  switchFormValues: {
    active: true,
    completed: false,
  },
  moduleProgressDataArray: [],
  moduleProgressItemsObj: {},
  clusterData: {
    isLoading: false,
    error: {},
    response: {
      data: []
    }
  },
  messageData: {
    isLoading: false,
    error: {},
    response: {
      data: {
        retryAfter: 0,
        historyMessages: []
      }
    }
  },
  chatMessageSending: {
    isLoading: false,
  },
  selectedRadioFilterValue: ""
};

const courseReducer = (state = courseReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_RADIO_VALUE:
      return {
        ...state,
        selectedRadioFilterValue: action.payload.value,
      }
    case RESET_ADMIN_LEVEL_DATA:
      return {
        ...state,
        adminStatus: { ...courseReducerDefaultState.adminStatus }
      }
    case FETCH_IS_ADMIN_LEVEL_USER_LOADING:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          isLoading: action.payload.isLoading
        }
      }
    case FETCH_IS_ADMIN_LEVEL_USER:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          ...action.payload,
          selected: action.payload.accounts?.[0]._id
        }
      }
    case CLEAR_TERMS_DATA:
      return {
        ...state,
        terms: { ...courseReducerDefaultState.terms }
      };
    case FETCH_TERMS:
      return {
        ...state,
        terms: { ...state.terms, ...action.payload }
      };
    case FETCH_TERMS_LOADING:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: action.payload.isLoading
        }
      };
    case SET_TERM:
      return {
        ...state,
        terms: {
          ...state.terms,
          selected: action.payload
        }
      };
    case SET_COURSE_FILTER:
      return {
        ...state,
        courseFilters: [...action.payload]
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          selected: action.payload
        }
      };
    case FETCH_COURSE:
      return {
        ...state,
        selected: { ...action.payload }
      };
    case CLEAR_COURSE:
      const chatArray = _get(state, 'messageData.response.data.historyMessages', []);
      const historyMessages = chatArray.length ?
        [
          ...chatArray,
          { ...action.payload }]
        :
        [{ ...action.payload }];

      return {
        ...state,
        selected: { ...courseReducerDefaultState.selected },
        messageData: {
          ...state.messageData,
          response: {
            data: {
              retryAfter: _get(courseReducerDefaultState, 'messageData.response.data.retryAfter', 0),
              historyMessages: historyMessages
            }
          }
        },
      };
    case FETCH_COURSE_LOADING:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: action.payload.isLoading,
        }
      };
    case FETCH_COURSES:
      return {
        ...state,
        list: { ...action.payload }
      };
    case FETCH_COURSES_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: action.payload.isLoading
        }
      };
    case FETCH_COURSE_REPORT:
      return {
        ...state,
        mainReport: action.payload
      };
    case CLEAR_COURSES:
      return {
        ...state,
        list: courseReducerDefaultState.list
      }
    case CLEAR_COURSE_REPORT:
      return {
        ...state,
        mainReport: courseReducerDefaultState.mainReport,
      }
    case FETCH_COURSE_REPORT_LOADING:
      return {
        ...state,
        mainReport: {
          ...courseReducerDefaultState.mainReport,
          isLoading: action.payload.isLoading
        }
      };
    case SET_SWITCH_FORM_VALUES:
      return {
        ...state,
        switchFormValues: action.payload
      };
    case RESET_MODULE_PROGRESS_DATA:
      return {
        ...state,
        moduleProgressDataArray: courseReducerDefaultState.moduleProgressDataArray,
        moduleProgressItemsObj: courseReducerDefaultState.moduleProgressItemsObj
      }
    case FETCH_COURSE_PROGRESS:
      return {
        ...state,
        moduleProgressDataArray: [...state.moduleProgressDataArray, { ...action.payload.data }]
      };
    case FETCH_MODULE_ITEM_DATA_LOADING:
      return {
        ...state,
        moduleProgressItemsObj: { ...state.moduleProgressItemsObj, ...action.payload }
      };
    case FETCH_MODULE_ITEM_DATA:
      return {
        ...state,
        moduleProgressItemsObj: { ...state.moduleProgressItemsObj, ...action.payload }
      };
    case FETCH_STUDENT_CLUSTER_DATA:
      return {
        ...state,
        clusterData: {
          ...action.payload,
        }
      };
    case RESET_STUDENT_CLASSIFICATION_DATA:
      return {
        ...state,
        messageData: {
          ...courseReducerDefaultState.messageData,
        },
        clusterData: {
          ...courseReducerDefaultState.clusterData,
        }
      };
    case FETCH_STUDENT_CLUSTER_DATA_LOADING:
      return {
        ...state,
        messageData: {
          ...courseReducerDefaultState.messageData,
        },
        clusterData: {
          ...courseReducerDefaultState.clusterData,
          isLoading: action.payload.isLoading
        }
      };
    case FETCH_CHAT_HISTORY_DATA:
      const { error, isLoading, response: { data } } = action.payload;

      return {
        ...state,
        messageData: {
          error,
          isLoading,
          response: {
            data: {
              retryAfter: _get(state, 'messageData.response.data.retryAfter', 0),
              courseId: data.courseId,
              historyMessages: [
                ..._get(state, 'messageData.response.data.historyMessages', []),
                ..._get(data, 'historyMessages', []),
              ]
            }
          }
        }
      };
    case FETCH_CHAT_HISTORY_ERROR_HANDEL:
      const retryAfter = _get(action.payload, 'response.data.retryAfter');

      const removeLastChat = () => {
        const chatArray = [..._get(state, 'messageData.response.data.historyMessages', [])];
        chatArray.pop();
        return chatArray;
      }

      return {
        ...state,
        messageData: {
          ...action.payload,
          response: {
            data: {
              retryAfter,
              historyMessages: retryAfter ?
                removeLastChat()
                :
                [
                  ..._get(state, 'messageData.response.data.historyMessages', []),
                  ..._get(action.payload, 'response.data.historyMessages', [])
                ]
            }
          },
        }
      };
    case FETCH_CHAT_HISTORY_DATA_LOADING:
      return {
        ...state,
        messageData: {
          ...courseReducerDefaultState.messageData,
          isLoading: action.payload.isLoading
        }
      };
    case RESET_CHAT_COUNT_DOWN_TIMER:
      return {
        ...state,
        messageData: {
          ...state.messageData,
          response: {
            data: {
              retryAfter: _get(courseReducerDefaultState, 'messageData.response.data.retryAfter', 0),
              historyMessages: [
                ..._get(state, 'messageData.response.data.historyMessages', []),
              ]
            }
          }
        }
      };
    case SEND_CHAT_MESSAGE_LOADING:
      return {
        ...state,
        chatMessageSending: {
          isLoading: action.payload.isLoading
        }
      };
    case UPDATE_SEND_CHAT:
      return {
        ...state,
        messageData: {
          ...state.messageData,
          response: {
            data: {
              retryAfter: _get(state, 'messageData.response.data.retryAfter', 0),
              historyMessages: [
                ..._get(state, 'messageData.response.data.historyMessages', []),
                { ...action.payload }
              ]
            }
          }
        }
      };
    case UPDATE_APPLICATION_LEVEL_ADMIN_USER:
      return {
        ...state,
        applicationAdmin: {
          ...state.applicationAdmin,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default courseReducer;
