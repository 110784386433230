import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, Row, Switch, Spin } from 'antd';
import { InfoCircleFilled, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  setMenuSelected,
  setBreadcrumb,
} from '../../../actions/dashboardManagement/layout.action';
import {
  fetchSettingData,
  saveDataPrivacySettingStatus
} from '../../../actions/dashboardManagement/dataPrivacyManagement.action';
import _get from 'lodash/get';
import {
  makeSettingsDataPrivacyManagerStatus
} from '../../../selectors/dashboardManagement/dataPrivacyManager.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import ConfirmationModal from './partials/confirmationModal';
import session from '../../../utils/session';

const sectionKey = _get(menuData, 'dataPrivacyManagement.key', '');
const sectionName = _get(menuData, 'dataPrivacyManagement.name', '');

const breadcrumbItems = [
  {
    key: 'admin',
    section: "Admin",
  },
  {
    key: sectionKey,
    section: sectionName,
  },
];

const { Title, Paragraph } = Typography;
const loadingIcon =
  <LoadingOutlined className='dataPrivacyManagement-setting-loading' />;

const DataPrivacyManagementSettings = () => {
  const [DataPrivacyManager, setDataPrivacyManager] = useState(false);
  const [isDataPrivacyManagerPopupOpen, setIsDataPrivacyManagerPopupOpen] = useState(false);

  const dispatch = useDispatch();

  const getDataPrivacyManagementData = useSelector(makeSettingsDataPrivacyManagerStatus);

  useEffect(() => {
    dispatch(fetchSettingData({
      "user_id": session.get([
        session.keys.innovativeMeta,
        "token"
      ]),
    }));

    dispatch(setMenuSelected(sectionKey));
    dispatch(setBreadcrumb(breadcrumbItems));
  }, []);

  useEffect(() => {
    setDataPrivacyManager(getDataPrivacyManagementData?.data_privacy_management_setting_enable);
  }, [getDataPrivacyManagementData]);

  const saveSelection = () => {
    dispatch(saveDataPrivacySettingStatus({
      dataPrivacyManagerEnable: DataPrivacyManager
    }));
  };

  const onChangeDataPrivacyManager = checked => {
    setDataPrivacyManager(checked);
    setIsDataPrivacyManagerPopupOpen(!!checked);

    if (!checked) {
      dispatch(saveDataPrivacySettingStatus({
        dataPrivacyManagerEnable: false
      }));
    }
  };
  return (
    <Spin spinning={false} indicator={loadingIcon}>
      <div className='student-cluster-settings in-container'>
        <Title level={4}>Data Privacy Management</Title>
        <Card className='switch-card'>
          <Paragraph className='switch-card-text'>
            Student all course access
          </Paragraph>
          <Row className='switch-section'>
            <span>Disable</span>
            <Switch
              size="small"
              className='status-switch'
              checked={DataPrivacyManager}
              onChange={onChangeDataPrivacyManager}
            />
            <span>Enable</span>
          </Row>
        </Card>

        <ConfirmationModal
          modalStatus={isDataPrivacyManagerPopupOpen}
          saveSelection={saveSelection}
          closeModal={setIsDataPrivacyManagerPopupOpen}
          setToggle={setDataPrivacyManager}
          title={"Please Confirm"}
        >
          <Paragraph>
            <Row className='info-row'>
              <InfoCircleOutlined />
              <Paragraph className='info-row-description'>
                You have turned on Data Privacy Management
              </Paragraph>
            </Row>
          </Paragraph>
        </ConfirmationModal>
      </div>
    </Spin>
  );
}

export default DataPrivacyManagementSettings;
