import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Modal, Space, Typography } from "antd";
import { SendOutlined } from '@ant-design/icons';
import messageSendingGif from "../../../../../assets/images/chatGPT/sending-chat.gif";
import { getIsMessageSending } from "../../../../../selectors/innovative/course.selector";
import { addNewMessageToChatHistory, sendChatMessage } from "../../../../../actions/innovative/course.action";
import { debounce as _debounce } from 'lodash';
import { InfoCircleFilled } from '@ant-design/icons';
import Countdown, { zeroPad } from 'react-countdown-now';

const { Title } = Typography;

const SUPPORT_EMAIL = process.env.REACT_APP_CLUSTER_ANALYZER_SUPPORT_EMAIL;
const disclaimerInfoArray = [
  { title: "AI Generator Usage:", info: "The Application incorporates an AI generator that generates text-based content, including but not limited to responses, suggestions, and recommendations. This content is generated based on patterns and data processed by the AI model.", },
  { title: "Accuracy and Reliability:", info: "While we strive to provide accurate and reliable content generated by our AI model, it may not always be error-free or up to date. Users should exercise discretion and verify information obtained from the AI generator, as it may not always reflect the most current data or context." },
  { title: "No Legal or Professional Advice:", info: "The content generated by the AI generator is not a substitute for professional advice or any other specialized expertise. Users should consult with qualified professionals for specific guidance & verification and not solely rely on the AI-generated content." },
  { title: "User Responsibility:", info: "Users of the Application are solely responsible for the use and interpretation of content generated by the AI. Octopus BI shall not be held liable for any actions, decisions, or consequences resulting from the use of the AI-generated content." },
  { title: "Privacy and Data Responsibility:", info: "Users are responsible for the data they input into the Application and any consequences arising from sharing sensitive or personal information. While Octopus BI is committed to protecting user privacy, users must exercise caution when providing personal data in the AI. Furthermore, Octopus BI strongly suggest users to not to enter any inputs that contains sensitive or personal information." },
  { title: "Updates and Changes:", info: "Octopus BI reserves the right to modify, update, or discontinue the AI generator or the Application at any time, without prior notice. We are not obligated to provide ongoing access to the AI generator." },
  { title: "Acceptance of Terms:", info: "By using the Application, users acknowledge and accept the terms and conditions of this Disclaimer. If you do not agree with any part of this Disclaimer, we advise against using the Application." },
  {
    title: "Contact Information:", info: <p>For questions, concerns, or inquiries related to this Disclaimer or the Application, please contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
      <br /><br />By using the Application and the AI generator, you agree to be bound by the terms and conditions outlined in this Disclaimer. We recommend reviewing this Disclaimer periodically for any updates or changes.<br /><br />Last updated: {process.env.REACT_APP_CLUSTER_ANALYZER_DISCLAIMER_LAST_UPDATE}
    </p>
  },
];

// remove special characters
const inputTextValidationPattern = /^[^`~#$^*=+{}\[\];<>]*$/;

const SendChatInput = ({
  isDisabled,
  scrollToEndOfChatRef,
  onCountDownComplete,
  startCountDownTimer,
  retryAt
}) => {
  const dispatch = useDispatch();
  const isMessageSending = useSelector(getIsMessageSending);
  const [userQuestion, setUserQuestion] = useState("");
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isInputTextValidationSuccess, setIsInputTextValidationSuccess] = useState(true);

  const closeModal = () => {
    setIsDisclaimerModalOpen(false);
  }

  const onMessageSend = () => {
    const message = {
      content: userQuestion,
      role: "user"
    }

    if (userQuestion.length && isInputTextValidationSuccess) {
      dispatch(addNewMessageToChatHistory(message))
      dispatch(sendChatMessage({ question: userQuestion }));
      setUserQuestion("");
    }
  }
  const onSearch = value => {
    setUserQuestion(value);
    debouncedInputField(value);
  };

  const debouncedInputField = useCallback(
    _debounce(value => {
      const regexValidationSuccess = inputTextValidationPattern.test(value);
      setIsInputTextValidationSuccess(regexValidationSuccess);
    }, 500),
    [],
  );

  const countDownRenderer = ({ minutes, seconds }) => {
    return <span>{`Please Try Again in ${minutes}:${zeroPad(seconds)}`}</span>;
  };

  return (
    <div className="chatInputBox">
      <div
        className={isInputTextValidationSuccess ? "chatInput" : "chatInputDangerContainer"}
        ref={scrollToEndOfChatRef}
      >
        <Input
          disabled={isDisabled || startCountDownTimer}
          size="large"
          placeholder={!startCountDownTimer && "Ask Anything"}
          onChange={(e) => {
            onSearch(e.target.value)
          }}
          onPressEnter={() => {
            onMessageSend();
          }}
          value={userQuestion}
          prefix={
            (startCountDownTimer && retryAt ) &&
            <Space>
              <Countdown
                renderer={countDownRenderer}
                date={new Date(retryAt)}
                onComplete={() => { onCountDownComplete() }}
              />
            </Space>
          }
          suffix={
            <div>
              {isMessageSending ?
                <div className="chatSendLoading">
                  <img src={messageSendingGif} alt="loading..." />
                </div>
                : <div
                  onClick={() => {
                    onMessageSend();
                  }}
                >
                  <SendOutlined />
                </div>}
            </div>
          }
        />
      </div>
      {!isInputTextValidationSuccess && (
        <div className="chatTextValidation">
          Special characters are not allowed
        </div>
      )}
      <div className="baseDisclaimer">
        Disclaimer: Beta Preview. Our Generative AI Engine may produce inaccurate insights.
        <a href="#_" onClick={() => {
          setIsDisclaimerModalOpen(true);
        }}>
          View Disclaimer
        </a>
      </div>
      <div>
        <div>
          <Modal
            title="Octopus BI | AI Generator Disclaimer"
            visible={isDisclaimerModalOpen}
            onOk={() => { closeModal() }}
            onCancel={() => { closeModal() }}
            okText="Understood"
            maskClosable={false}
          >
            <p>
              This disclaimer is intended to inform users of Tentacle about the use of “Octopus AI” feature, developed within the Application. Please read this Disclaimer carefully before using the Application.
            </p>

            {disclaimerInfoArray.map((disclaimer) => <div className='cluster-info-section-card-text'>
              <Space>
                <InfoCircleFilled style={{ color: "#4A44E5" }} />
                <Title level={4} className='sub-title'>
                  {disclaimer.title}
                </Title>
              </Space>
              <p>
                {disclaimer.info}
              </p>
            </div>)
            }
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SendChatInput;
