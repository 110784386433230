import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Input, Form, Upload, notification, Button, Spin } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { upperFirst } from 'lodash';
import {
  makeHelpFeedBackLastSubmit,
  makeHelpFeedbackSubmitting,
} from '../../../../selectors/innovative/generic.selectors';
import { validateEmail } from '../../../../utils/innovative/helpers';
import FeedbackSuccessModal from './FeedbackSuccessModal';
import {
  HELP_FEEDBACK_ATTACHMENT_MAX_SIZE,
  HELP_FEEDBACK_SUPPORTED_FILE_TYPES,
  HELP_FEEDBACK_MAX_ATTACHMENT_COUNT,
} from '../../../../utils/innovative/constants';
import { submitHelpFeedBack } from '../../../../actions/innovative/generic.action';
const { Item } = Form;
const { TextArea } = Input;

const defaultValues = {
  name: '',
  email: '',
  feedback: '',
};
const defaultErrors = {
  name: null,
  email: null,
  feedback: null,
};

const FeedbackModal = props => {
  const { openStatus, submit, setModalStatus, maxCount } = props;
  const [formValues, setFormValues] = useState(defaultValues);
  const [validateErrors, setValidateErrors] = useState(defaultErrors);
  const [fileList, setFileList] = useState([]);
  const [successModalStutus, setSuccessModalStatus] = useState(false);
  const [feedbackForm] = Form.useForm();

  const feedbackSubmitting = useSelector(makeHelpFeedbackSubmitting);
  const lastFeedBack = useSelector(makeHelpFeedBackLastSubmit);

  useEffect(() => {
    if (lastFeedBack) {
      handleCancel();
      setSuccessModalStatus(true);
    }
  }, [lastFeedBack]);

  useEffect(() => {
    if (openStatus) {
      setSuccessModalStatus(false);
    }
  }, [openStatus]);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 500);
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const formData = new FormData();
    const { name, email, feedback } = formValues;

    fileList.forEach(file => {
      const fileAppend = file.originFileObj;
      formData.append('attachments', fileAppend, fileAppend.name);
    });

    formData.append('name', name);
    formData.append('email', email);
    formData.append('feedback', feedback);

    submit(formData);
  };

  const handleCancel = () => {
    if (feedbackSubmitting) return;

    setFormValues(defaultValues);
    setValidateErrors(defaultErrors);
    setFileList([]);
    feedbackForm.resetFields(['name', 'email', 'feedback']);
    setModalStatus(false);
  };

  const onChangeForm = (event, field) => {
    const { value } = event.target;
    let message = null;

    if (!value) {
      message = `${upperFirst(field)} field is required.`;
    }

    if (field === 'email' && !validateEmail(value)) {
      message = 'Invalid email address.';
    }

    setFormValues(prevState => ({
      ...prevState,
      [field]: value,
    }));

    setValidateErrors(prevState => ({
      ...prevState,
      [field]: message,
    }));
  };

  const validate = () => {
    const { name, email, feedback } = formValues;
    let nameError = '';
    let emailError = '';
    let feedbackError = '';

    if (!name) {
      nameError = 'Name field is required.';
    }

    if (!feedback) {
      feedbackError = 'Feedback field is required.';
    }

    if (!email) {
      emailError = 'Email field is required.';
    }

    if (!validateEmail(email)) {
      emailError = 'Invalid email address.';
    }

    setValidateErrors({
      name: nameError,
      email: emailError,
      feedback: feedbackError,
    });
    feedbackForm.validateFields(['name', 'email', 'feedback']);

    if (!(name && email && feedback && validateEmail(email))) return false;

    return true;
  };

  const validateFileType = file => {
    const { type } = file;

    if (!HELP_FEEDBACK_SUPPORTED_FILE_TYPES.includes(type)) {
      return false;
    }

    return true;
  };

  const validateFileSize = file => {
    const { size } = file;

    if (size > HELP_FEEDBACK_ATTACHMENT_MAX_SIZE) {
      return false;
    }

    return true;
  };

  const beforeUpload = file => {
    if (!validateFileType(file)) {
      notification.info({
        message: 'Info',
        description: 'This file type is not supported!',
      });
      return false;
    }

    if (!validateFileSize(file)) {
      notification.info({
        message: 'Info',
        description: 'File size should be less than or equal to 5MB!',
      });
      return false;
    }

    return true;
  };

  const onUploadChange = params => {
    if (params && params.fileList) {
      const files = [];
      params.fileList.forEach(file => {
        if (file && validateFileSize(file) && validateFileType(file)) {
          files.push(file);
        }
      });
      setFileList(files);
    }
  };

  const onCloseSuccessModal = () => {
    setSuccessModalStatus(false);
    handleCancel();
  };

  const getTitle = () => (
    <h5>
      <strong>Send Feedback</strong>
    </h5>
  );

  const getModalContent = currentContent => {
    if (feedbackSubmitting) {
      return (
        <Spin indicator={<LoadingOutlined />} wrapperClassName="help-feedback-modal-overlay">
          {currentContent}
        </Spin>
      );
    }

    return currentContent;
  };

  return (
    <>
      <Modal
        title={getTitle()}
        visible={openStatus}
        onOk={handleSubmit}
        onCancel={handleCancel}
        modalRender={getModalContent}
        okText="Submit"
      >
        <Form layout="vertical" form={feedbackForm}>
          <Item
            label="Name"
            name="name"
            rules={[{ required: true }]}
            help={validateErrors.name}
          >
            <Input
              value={formValues.name}
              onChange={event => {
                onChangeForm(event, 'name');
              }}
            />
          </Item>
          <Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email' }]}
            help={validateErrors.email}
          >
            <Input
              value={formValues.email}
              onChange={event => {
                onChangeForm(event, 'email');
              }}
            />
          </Item>
          <Item
            label="Feedback"
            name="feedback"
            rules={[{ required: true }]}
            help={validateErrors.feedback}
          >
            <TextArea
              rows={4}
              value={formValues.feedback}
              onChange={event => {
                onChangeForm(event, 'feedback');
              }}
            />
          </Item>
          <Item label="Attachment">
            <Upload
              beforeUpload={beforeUpload}
              onChange={onUploadChange}
              fileList={fileList}
              customRequest={dummyRequest}
              maxCount={maxCount}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Item>
        </Form>
      </Modal>
      <FeedbackSuccessModal
        openStatus={successModalStutus}
        onClose={onCloseSuccessModal}
      />
    </>
  );
};

FeedbackModal.propTypes = {
  openStatus: PropTypes.bool,
  submit: PropTypes.func,
  setModalStatus: PropTypes.func,
  maxCount: PropTypes.number,
};

FeedbackModal.defaultProps = {
  maxCount: HELP_FEEDBACK_MAX_ATTACHMENT_COUNT,
};

export default FeedbackModal;
