import {
    FETCH_DASHBOARD_LOADING,
    FETCH_DASHBOARD,
    FETCH_USER_ROLES_LOADING,
    FETCH_USER_ROLES,
    SAVE_SHARE_ROLES_LOADING,
    SAVE_SHARE_ROLES,
    SELECT_DASHBOARD,
    SAVE_DASHBOARD_LOADING,
    SAVE_DASHBOARD,
    FETCH_CREDENTIAL_MANAGEMENT_DATA,
    FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING,
    UPDATE_DEVELOPER_KEY_DATA,
    UPDATE_DEVELOPER_KEY_DATA_LOADING,
    ADD_ADMIN_TOKEN_DATA,
    ADD_ADMIN_TOKEN_LOADING,
    DELETE_ADMIN_TOKEN_DATA,
    DELETE_ADMIN_TOKEN_LOADING,
    FETCHING_GET_INFO,
    GET_INFO,
} from "../../constant/actionTypes";

const dashboardReducerDefaultState = {
    list: {
        isLoading: false,
        error: false,
        data: [],
    },
    userRoles: {
        isLoading: false,
        error: false,
        data: {},
    },
    shareDashboard: {
        isLoading: false,
        error: false,
    },
    saveDashboard: {
        isLoading: false,
        error: false,
    },
    credentialsManagement: {
        isLoading: false,
        error: false,
    },
    selected: null,
    baseInfo: {
        isLoading: false,
        error: false,
        response: {},
    },
};

const dashboardReducer = (state = dashboardReducerDefaultState, action) => {
    switch (action.type) {
        case SELECT_DASHBOARD:
            return {
                ...state,
                selected: action.payload
            }
        case FETCH_DASHBOARD_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: action.payload
                }
            }
        case FETCH_DASHBOARD:
            return {
                ...state,
                list: {
                    ...state.list,
                    ...action.payload
                }
            }
        case UPDATE_DEVELOPER_KEY_DATA_LOADING:
        case UPDATE_DEVELOPER_KEY_DATA:
        case ADD_ADMIN_TOKEN_DATA:
        case ADD_ADMIN_TOKEN_LOADING:
        case DELETE_ADMIN_TOKEN_DATA:
        case DELETE_ADMIN_TOKEN_LOADING:
            return {
                ...state,
                credentialsManagement: {
                    ...state.credentialsManagement,
                    isLoading: action.payload
                }
            }
        case FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING:
            return {
                ...state,
                credentialsManagement: {
                    ...state.credentialsManagement,
                    isLoading: action.payload
                }
            }
        case FETCH_CREDENTIAL_MANAGEMENT_DATA:
            return {
                ...state,
                credentialsManagement: {
                    ...state.credentialsManagement,
                    ...action.payload
                }
            }
        case FETCH_USER_ROLES_LOADING:
            return {
                ...state,
                userRoles: {
                    ...state.userRoles,
                    isLoading: action.payload
                }
            }
        case FETCH_USER_ROLES:
            return {
                ...state,
                userRoles: {
                    ...state.userRoles,
                    ...action.payload
                }
            }
        case SAVE_SHARE_ROLES_LOADING:
            return {
                ...state,
                shareDashboard: {
                    ...state.shareDashboard,
                    isLoading: action.payload
                }
            }
        case SAVE_SHARE_ROLES:
            return {
                ...state,
                shareDashboard: {
                    ...state.shareDashboard,
                    ...action.payload
                }
            }
        case SAVE_DASHBOARD_LOADING:
            return {
                ...state,
                saveDashboard: {
                    ...state.saveDashboard,
                    isLoading: action.payload
                }
            }
        case SAVE_DASHBOARD:
            return {
                ...state,
                saveDashboard: {
                    ...state.saveDashboard,
                    ...action.payload
                }
            }
        case FETCHING_GET_INFO:
            return {
                ...state,
                baseInfo:{
                    ...state.baseInfo,
                    isLoading: action.payload
                }
            }
        case GET_INFO:
            return {
                ...state,
                baseInfo:{
                    ...state.baseInfo,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default dashboardReducer;