import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Space, Select } from "antd";
import SelectedUserView from "./selectedUserView";
import { InfoCircleOutlined } from '@ant-design/icons';
import { searchUserByIdOrName, setAppTestingUsers } from "../../../../../../../actions/dashboardManagement/app.testing.action";
import { getAvailableSearchedUsers } from "../../../../../../../selectors/dashboardManagement/app.testing.selector";
import PopUpModal from "../../../../../../common/popup-modal/popup-modal";
import { onError } from "../../../../../../../utils/notificationHandler";
import { compareArrays } from "../../../../../../../utils/general";

const MAXIMUM_ALLOWED_TEST_USERS = 5;
const MINIMUM_CHARACTER_LENGTH_TO_SEARCH = 3;

const SelectUser = ({
  setIsMaintenancePopupOpen,
  isModalOpen,
  dashboardId,
  accountId,
  accessCode,
  maintenanceModeUsersArray = []
}) => {
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  //selectors
  const searchedUsers = useSelector(getAvailableSearchedUsers);

  const onChange = value => {
    setSearchText(value);
    value.length >= MINIMUM_CHARACTER_LENGTH_TO_SEARCH && dispatch(searchUserByIdOrName({
      value,
      filteredBy: "user",
      access_code: accessCode
    }));
  }

  const setButtonStatus = () => {
    if (maintenanceModeUsersArray?.length) {
      if (compareArrays(maintenanceModeUsersArray, selectedUsers)) {
        setSubmitButtonDisabled(true);
        return;
      }

      setSubmitButtonDisabled(false);
      return;
    }
    if (selectedUsers.length) {
      setSubmitButtonDisabled(false);
      return;
    }
    setSubmitButtonDisabled(true);
    return;
  }

  useEffect(() => {
    setButtonStatus();
  }, [selectedUsers, maintenanceModeUsersArray]);

  useEffect(() => {
    setSelectedUsers(maintenanceModeUsersArray);
  }, [maintenanceModeUsersArray]);

  useEffect(() => {
    maintenanceModeUsersArray.length && setSelectedUsers(maintenanceModeUsersArray);
  }, [maintenanceModeUsersArray]);

  const onRemoveSelectedUser = selectedUser => {
    const selectedUserArray = selectedUsers.filter(user => user.userId !== selectedUser.userId);
    setSelectedUsers(selectedUserArray);
  };

  const onClickUser = selectedUser => {
    if (selectedUsers?.length >= MAXIMUM_ALLOWED_TEST_USERS) {
      onError("Maximum 5 users are allowed!");
      return;
    }
    const userExistSelectedArray = selectedUsers.find(user => user.userId === selectedUser.userId);
    if (!userExistSelectedArray) {
      setSelectedUsers([...selectedUsers, selectedUser])
    }
  }

  const onUserSelect = user => {
    onClickUser(user);
    setSearchText('');
  }

  const dropdownRender = user => {
    return (
      <div
        onClick={() => onUserSelect(user)}
      >
        {user.name}
      </div>
    )
  };

  const selectOptions = () => {
    return searchedUsers && searchedUsers.map(user => {
      return {
        value: user.name,
        label: dropdownRender(user)
      }
    });
  }

  const onModalConfirm = () => {
    setSearchText('');
    setIsMaintenancePopupOpen(false);
    dispatch(setAppTestingUsers({
      dashboard_id: dashboardId,
      user_groups: { [accountId]: selectedUsers },
    }));
  }

  return (
    <PopUpModal
      title={"Select Users"}
      okText={"OK"}
      onPressOk={onModalConfirm}
      cancelText={"Cancel"}
      setIsModalVisible={setIsMaintenancePopupOpen}
      isModalVisible={isModalOpen}
      okButtonDisabled={isSubmitButtonDisabled}
    >
      <Col>
        <Row>
          <Col span={24}>
            <div>
              <Select
                showSearch
                value={searchText}
                onSearch={onChange}
                style={{
                  width: '100%',
                }}
                options={selectOptions()}
              />
            </div>
          </Col>
        </Row>
        <Row >
          <SelectedUserView
            selectedUserArray={selectedUsers}
            onRemoveSelectedUser={onRemoveSelectedUser}
          />
        </Row>
        <Row >
          <Space className="maintenanceModePopupText">
            <InfoCircleOutlined />
            Please ask these users to login and test the app.
          </Space>
        </Row>
      </Col>

    </PopUpModal>
  )
}

export default SelectUser;
