import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserActionButtons from "./userActionButtons";
import { onUserDelete } from "../../../../actions/userManagement/userManagement.action";
import { getUserManagementUserLoading } from "../../../../selectors/userManagement/userManagement.selector";
import Loader from "../../../../components/dashboardCommon/Loader";

const UserManagementTable = ({
  userList,
  setEnableSideDrawer,
  setSelectedUsersArray,
  selectedUsersArray = [],
}) => {
  const [initialSelectedUser, setInitialSelectedUser] = useState({});
  const isUserManagementDataLoading = useSelector(getUserManagementUserLoading);

  useEffect(() => {
    selectedUsersArray.length ? setInitialSelectedUser(selectedUsersArray[0]) : setInitialSelectedUser({});
  }, [selectedUsersArray]);

  const isCheckboxDisabled = record => {
    if (initialSelectedUser && Object.keys(initialSelectedUser).length === 0) {
      return false;
    }
    if (initialSelectedUser?.account_id !== record?.account_id) {
      return true;
    }
    return false;
  }

  const dispatch = useDispatch();

  const onDeleteAction = selectedUser => {
    dispatch(onUserDelete({
      userId: selectedUser.user_id,
      accountId: selectedUser.account_id,
    }));
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user_name',
    },
    {
      title: 'Account',
      dataIndex: 'account_type',
    },
    {
      title: 'Role',
      dataIndex: 'account_role',
    },
    {
      dataIndex: 'action_buttons',
      render: (_, record) => <UserActionButtons
        userData={record}
        onDeleteAction={onDeleteAction}
        setEnableSideDrawer={setEnableSideDrawer}
        setSelectedUsersArray={setSelectedUsersArray}
        disabled={selectedUsersArray.length > 1}
      />,
    },
  ];

  const rowSelection = {
    type: "checkbox",
    hideSelectAll: true,
    selectedRowKeys: selectedUsersArray.length ? selectedUsersArray.map(user => user.user_id) : [],
    getCheckboxProps: record => ({
      disabled: isCheckboxDisabled(record),
      name: record.name,
    }),
    onSelect: (_, __, selectedRows) => {
      setSelectedUsersArray(selectedRows);
    },
    renderCell(_, record, __, node) {
      if (isCheckboxDisabled(record)) {
        return (
          <Tooltip title={`Only '${initialSelectedUser?.account_type}' Account type can be selected.`}>
            {node}
          </Tooltip>);
      }
      return node;
    },
  };

  return (
    <div>
      <Loader isLoading={isUserManagementDataLoading} />
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        dataSource={userList ?? []}
        rowKey={record => record?.user_id}
      />
    </div>
  )
}

export default UserManagementTable;
