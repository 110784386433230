import React, { Fragment } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

const PopUpModal = ({
  title,
  children,
  okText,
  onPressOk,
  cancelText,
  setIsModalVisible,
  isModalVisible,
  okButtonDisabled = false,
  onCancel,
}) => {

  const handleSubmit = () => {
    onPressOk && onPressOk();
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleSubmit}
        okText={okText}
        cancelText={cancelText ? cancelText : null}
        onCancel={() => {
          setIsModalVisible(false);
          onCancel && onCancel();
        }}
        okButtonProps={{ disabled: okButtonDisabled }}
      >
        {children}
      </Modal>
    </Fragment>
  )
}

PopUpModal.propTypes = {
  title: PropTypes.string,
  setIsModalVisible: PropTypes.func,
  isModalVisible: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onPressOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default PopUpModal;
