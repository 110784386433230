import {    
    DATAPRIVACY_SETTINGS_DATA,
    FETCHING_DATAPRIVACY_SETTINGS_DATA,
    SET_DATA_PRIVACY_MANAGER_LOADING,
    SET_DATA_PRIVACY_MANAGER_STATUS,  
  } from '../../constant/actionTypes';
  
  const initialState = {
    dataPrivacy: {
      data:{},
      error:false,
      isLoading: false,
    },
    isLoading: false  
  };
  
  const dataPrivacyManagementReducer = (state = initialState, action) => {
    const { type, payload } = action;    
    switch (type) {
      case SET_DATA_PRIVACY_MANAGER_LOADING:
        return {
          ...state,
          isLoading: payload,
        };
      case SET_DATA_PRIVACY_MANAGER_STATUS:
        return {
          ...state,
          ...payload,
        };
      case FETCHING_DATAPRIVACY_SETTINGS_DATA:
      case DATAPRIVACY_SETTINGS_DATA:
          return {
            ...state,
            dataPrivacy: {
            ...action.payload
            }
        };
      default:
        return state;
    };
  }
  
  export default dataPrivacyManagementReducer;
  