import React, { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as d3 from 'd3';
import { Switch, Row } from 'antd';

import { useD3 } from '../../../../utils/customHooks';

import CustomAvatar from './partials/CustomAvatar';
import DetailModal from './partials/DetailModal';

import { plotLines } from './utils/ChartData';

const fullHeight = 600;
const margin = { top: 50, right: 20, bottom: 30, left: 55 };
const height = fullHeight - margin.top - margin.bottom;

const ClusterChart = ({ data = [], tabRef }) => {

  const divRef = useRef(null);
  const fullWidth = tabRef.current?.offsetWidth;
  const [width, setWidth] = useState(fullWidth - (margin.left + margin.right));

  useEffect(() => {
    let timeout;
    const updateWidth = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setWidth(divRef.current?.offsetWidth - (margin.left + margin.right));
      }, 200)
    };
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  const [showUserLabels, setShowUserLabels] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedNode, setSelectedNode] = useState([])
  const [selectedNodeColor, setSelectedNodeColor] = useState('')

  const onItemClick = (users, color, value) => {
    // show popups
    setSelectedNode({ users, value });
    setModalStatus(true);
    setSelectedNodeColor(color)
  };

  const onShowNamesSwitch = value => {
    setShowUserLabels(value);
  };

  const getCircleStroke = items => {
    if (items.length > 1) {
      return 3
    }

    return 2;
  };

  const svgRef = useD3((svg) => {
    const xScale = d3.scaleLinear()
      .range([margin.left, width])
      .domain([0, 100]);

    const yScale =
      d3.scaleLinear()
        .range([height, margin.top])
        .domain([0, 100]);

    const line = d3
      .line()
      .x((d) => xScale(d.engagement))
      .y((d) => yScale(d.performance));

    // plotLines
    plotLines.forEach(lineObj => {
      svg
        .append('path')
        .datum(lineObj.coordinates)
        .attr('fill', 'none')
        .attr('stroke', lineObj.color)
        .attr('class', 'plot-line')
        .attr('stroke-width', lineObj.width)
        .attr('d', line)
    });

    // x-Axis
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .attr('class', 'axis-line')
      .call(
        d3.axisBottom(xScale).tickFormat(value => `${value} %`)
      );

    svg
      .append('text')
      .attr(
        'transform',
        `translate(${width - margin.left}, ${fullHeight - margin.bottom})`,
      )
      .text('ENGAGEMENT')
      .attr('class', 'axis-label');

    // y-Axis
    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'axis-line')
      .call(
        d3.axisLeft(yScale).tickFormat(value => `${value} %`)
      );

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', -100)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .attr('class', 'axis-label')
      .text('PERFORMANCE');

      data.length && data.forEach((groupObj, groupIndex) => {
      const { values, group, color } = groupObj;
      const averageData = groupObj.center

      values.forEach((value, index) => {
        // lines connected to cluster elements with center
        const lineData = [value, averageData[0]];
        const key = `score_${groupIndex}_${index}`;
        const { users } = value;
        const avatar = ReactDOMServer.renderToString(
          <CustomAvatar list={users} color={color} />
        );
        svg
          .append('path')
          .datum(lineData)
          .attr('fill', 'none')
          .attr('stroke', '#000000')
          .attr('stroke-dasharray', '2 5')
          .attr('stroke-width', 2)
          .attr('d', line);

        // create cluster elements
        svg
          .selectAll(`.dot-${key} item-dots`)
          .data([value])
          .enter()
          .append('circle')
          .attr('class', `dot-${key}`)
          .attr('r', 20)
          .attr('cx', (d) => xScale(d.engagement))
          .attr('cy', (d) => yScale(d.performance))
          .style('stroke', color)
          .style('fill', '#FFFFFF')
          .style('stroke-width', getCircleStroke(users))
          .style('display', 'block');

        // 20 is radius of the circle. width and height of the image should be twice as radius
        svg.append("foreignObject")
          .attr("x", xScale(value.engagement) - 19.5)
          .attr("y", yScale(value.performance) - 19.5)
          .attr("class", 'avatar-tag')
          .attr("width", "39px")
          .html(avatar)
          .attr("height", "39px")
          .style("cursor", 'pointer')
          .on('click', () => { onItemClick(users, color, value) });

        showUserLabels && users.length === 1 && (
          svg.append("foreignObject")
            .attr("x", xScale(value.engagement) - 50)
            .attr("y", yScale(value.performance) + 30)
            .attr("class", 'name-tag')
            .html(`<span>${users[0].name}</span>`)
        )
      });

      // To make sure that cluster center elements are created at last
      setTimeout(() => {
        svg
          .selectAll(`.dot-${group}`)
          .data(averageData)
          .enter()
          .append('circle')
          .attr('class', `dot-${group}`)
          .attr('r', 5)
          .attr('cx', (d) => xScale(d.engagement))
          .attr('cy', (d) => yScale(d.performance))
          .style('fill', color);
      }, 500);

    });
  },
    JSON.stringify(data),
    showUserLabels,
    width,
  );

  return (
    <div ref={divRef}>
      <div className='clusterSection' >
        <svg
          ref={svgRef}
          width={width}
          height={fullHeight}
          style={{ display: 'block', position: 'relative', margin: "20px" }}
          className='chartContainer'
        />
        <Row className='bottom-row'>
          {/* <div className='name-switch'>
          <Switch
            checked={showUserLabels}
            onChange={onShowNamesSwitch}
            size='small'
          />
          <p>Show Names</p>
        </div> */}
          <Row className='legend'>
            <div className='legend-item'>
              <span className='legend-circle low-low' />
              <div className='legend-label'>
                <p>Low Engagement</p>
                <p>Low Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle high-low' />
              <div className='legend-label'>
                <p>High Engagement</p>
                <p>Low Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle black low-high' />
              <div className='legend-label'>
                <p>Low Engagement</p>
                <p>High Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle high-high' />
              <div className='legend-label'>
                <p>High Engagement</p>
                <p>High Performance</p>
              </div>
            </div >
          </Row>
        </Row>
        <DetailModal
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          selectedNodeColor={selectedNodeColor}
          setSelectedNodeColor={setSelectedNodeColor}
        />
      </div>
    </div>
  );
};

export default ClusterChart;