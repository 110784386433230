import React, { useEffect } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { getAddUserAccountsList } from "../../../../selectors/userManagement/userManagement.selector";

const SelectAccount = ({ selectedAccount, setSelectedAccount }) => {
  const accountList = useSelector(getAddUserAccountsList);

  const defaultSelectedAccountValue = accountList.length ? accountList[0].userName : "";

  useEffect(() => {
    if (accountList.length && (Object.keys(selectedAccount).length === 0)) {
      setSelectedAccount(accountList[0]);
    }
  }, [accountList]);

  const onAccountSelect = (_, data) => {
    setSelectedAccount(data);
  };

  const accountOptionArray = () => {
    if (!accountList.length) {
      return [];
    }

    return accountList.map(account => (
      {
        value: account.userName,
        ...account,
      }
    ))
  };

  return (
    <Select
      defaultValue={defaultSelectedAccountValue}
      options={accountOptionArray()}
      onSelect={onAccountSelect}
    />
  )
}

export default SelectAccount;
