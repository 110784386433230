import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
  getStudentClusterDataLoading, 
  getIsChatMessagesLoading, 
  getIsMessageSending, 
  getChatMessagesErrors, 
  getRetryChatAfter, 
  makeClusterDataError, 
  getChatCourseId, 
  makeInnovativeCourseResponseData, 
  getChatMessages
} from "../../../../selectors/innovative/course.selector";
import ChatCard from "./chatCard";
import chatLoadingGif from "../../../../assets/images/chatGPT/chat-loading.gif";
import SendChatInput from "./partials/sendChatInput";
import { fetchChatHistory, resetChatCountDownTimer } from "../../../../actions/innovative/course.action";

const ChatBoxContainer = ({ isClusterCountDownOn }) => {
  const scrollToEndOfChatRef = useRef(null);
  const dispatch = useDispatch();

  const [startCountDownTimer, setStartCountDownTimer] = useState(false);
  const [isChatRelatedToCourse, setIsChatRelatedToCourse] = useState(false);

  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const isMessagesLoading = useSelector(getIsChatMessagesLoading);
  const isMessageSending = useSelector(getIsMessageSending);
  const chatErrors = useSelector(getChatMessagesErrors);
  const retryAt = useSelector(getRetryChatAfter);
  const clusterDataError = useSelector(makeClusterDataError);
  const chatCourseId = useSelector(getChatCourseId);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const chatDataArray = useSelector(getChatMessages);

  const onCountDownComplete = () => {
    setStartCountDownTimer(false);
    dispatch(resetChatCountDownTimer());
    chatDataArray && !chatDataArray.length && dispatch(fetchChatHistory());
  }

  useEffect(() => {
    chatCourseId && setIsChatRelatedToCourse(chatCourseId === selectedCourse.course_id);
  }, [chatCourseId]);

  useEffect(() => {
    if (chatErrors && chatErrors.status === 429 && retryAt) {
      setStartCountDownTimer(true);
    }
  }, [chatErrors, retryAt, clusterDataError, chatDataArray, isChatRelatedToCourse, isMessageSending]);

  const renderMessages = () => {
    return (
      <div>
        <div >
          {chatDataArray.map((chatData, index) => {
            if (chatData.role === "assistant") {
              return <ChatCard scrollToEndOfChatRef={scrollToEndOfChatRef} key={index} chatData={chatData} align={"left"} />
            }

            return <ChatCard chatData={chatData} />
          })}
        </div>
        <SendChatInput
          isDisabled={isMessageSending}
          scrollToEndOfChatRef={scrollToEndOfChatRef}
          setStartCountDownTimer={setStartCountDownTimer}
          startCountDownTimer={startCountDownTimer}
          retryAt={retryAt}
          onCountDownComplete={onCountDownComplete}
        />
      </div>
    )
  }

  const renderCard = () => {
    if (chatDataArray.length && !clusterDataError) {
      return (
        <div>
          {isChatRelatedToCourse && renderMessages()}
        </div>
      );
    }

    return (
      <div className="chatContentWrapper">
        {
          isMessagesLoading &&
          (
            <div className="chatLoading">
              <img src={chatLoadingGif} alt="loading..." />
            </div>
          )
        }
        {
          <SendChatInput
            isDisabled={true}
            scrollToEndOfChatRef={scrollToEndOfChatRef}
            setStartCountDownTimer={setStartCountDownTimer}
            startCountDownTimer={startCountDownTimer}
            retryAt={retryAt}
            onCountDownComplete={onCountDownComplete}
          />
        }
      </div>
    )
  }

  return (
    <div className="chatWrapper">
      {(!isClusterDataLoading && !isClusterCountDownOn) && renderCard()}
    </div>
  )
}

export default ChatBoxContainer
