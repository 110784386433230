import {
    DATAPRIVACY_SETTINGS_DATA,
    FETCHING_DATAPRIVACY_SETTINGS_DATA,
    SET_DATA_PRIVACY_MANAGER_LOADING,    
  } from '../../constant/actionTypes';
  
  import service from "../../utils/customServices/dashboardManagement";
  
  const setDataPrivacySettingLoading = payload => dispatch => {
    dispatch({
      type: SET_DATA_PRIVACY_MANAGER_LOADING,
      payload,
    });
  };
  
  const setDataPrivacySettingStatus = payload => dispatch => {
    const data = {
      dataPrivacy: {
        data_privacy_management_setting_enable: payload.dataPrivacyManagerEnable,         
      }
    }
  
    dispatch({
      type: DATAPRIVACY_SETTINGS_DATA,
      payload: {
        isLoading: false,
        error: null,
        data
      },
    });
  }
  
  export const getDataPrivacySettingStatus = () => dispatch => {
    dispatch(
      setDataPrivacySettingLoading(true),
    );
  };
  
  export const saveDataPrivacySettingStatus = (data) => dispatch => {
    dispatch(
      setDataPrivacySettingLoading(true),
    );
  
    service.make({
      service: "insights/settings/global/dataPrivacyManagementSettingSave",
      method: "POST",
      authorized: true,
      data,
    }).then(response => {
      dispatch(setDataPrivacySettingStatus(data));
    }).catch(error => {});
  };
  
  const fetchingDataPrivacySettingData = (isLoading = true) => dispatch => {
    dispatch({
      type: FETCHING_DATAPRIVACY_SETTINGS_DATA,
      payload: {isLoading,
      error:false,
      data:{}},
    });
  }
  
  const dataPrivacySettingData = (response) => dispatch => {
    const data = {
      dataPrivacy: { 
        ...response.data,
      },
    };

    dispatch({
      type: DATAPRIVACY_SETTINGS_DATA,
      payload: {
        isLoading: false,
        error: response.error,
        data
      },
    });    
  };
  
  
  export const fetchSettingData = (data) => dispatch => {
    dispatch(
      fetchingDataPrivacySettingData(true),
    );
  
    service.make({
      service: "insights/settings/global/dataPrivacyManagementSettingFetch",
      method: "POST",
      authorized: true,
      data,
    }).then(response => {
      dispatch(dataPrivacySettingData(response));
    }).catch(error => {
      dispatch(dataPrivacySettingData(error));
    })
  };
  