import React, { useEffect, useState } from 'react';
import { Button, Menu, Tooltip } from 'antd';
import _debounce from "lodash/debounce";
import _isArray from "lodash/isArray";
import _size from "lodash/size";
import { NotificationOutlined, LockOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from 'react-redux';
import {
  makeInnovativeCoursesPaginationData,
  makeInnovativeCoursesResponseData,
  makeInnovativeCourseResponseData,
  makeInnovativeCourseLoading,
  getSelectedAccount,
  makeInnovativeCourseStatus,
  getSelectedTerm,
  getfilterDateForFetchCourse,
  getIsApplicationAdmin,
} from '../../../../../selectors/innovative/course.selector';
import {
  clearCourseData,
  clearCourseReport,
  fetchCourses,
  updateApplicationLevelAdmin,
} from '../../../../../actions/innovative/course.action';
import useCourseHandlers from '../../../../../utils/innovative/coursehandlers';
import Search from '../../form/Search';
import session from '../../../../../utils/session';
import { get as _get} from "lodash";

const CustomPaginator = ({ dateFilterKey }) => {
  const courses = useSelector(makeInnovativeCoursesResponseData);
  const pagination = useSelector(makeInnovativeCoursesPaginationData);
  const selectedDateFilter = useSelector(getfilterDateForFetchCourse);
  const selectedAccountId = useSelector(getSelectedAccount);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const [searchCourseText, setCourseSearchText] = useState('');

  const parsedUrl = new URL(window.location.href);
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManagementData, "is_admin", false);

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  };

  const dispatch = useDispatch();

  const { selectCourse } = useCourseHandlers();

  const selectedCoursesLoading = useSelector(makeInnovativeCourseLoading);
  const courseStateFilter = useSelector(makeInnovativeCourseStatus);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const selectedTerm = useSelector(getSelectedTerm);

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs,
    }));
  }, [actAs]);

  const onCourseSearch = _debounce((keyword) => {
    if (_size(keyword) === 0 || _size(keyword) > 2) {
      setCourseSearchText(keyword);
      dispatch(clearCourseReport());
      if (keyword) {
        return handlePagination(null, keyword);
      }
      return handlePagination();
    }
    return;
  }, 500);

  const onSearch = (event) => {
    const { value } = event.target;
    onCourseSearch(value);
  }

  const handlePagination = (pagination = null, searchTerm = '') => {
    let reqBody = {
      filterCourseStates: courseStateFilter,
    }

    if (searchTerm) {
      reqBody.searchTerm = searchTerm;
    }

    if (selectedAccountId) {
      reqBody.accountId = selectedAccountId;
    }

    if (pagination) {
      reqBody.pagination = pagination;
    }

    if (selectedTerm) {
      reqBody.term_ids = [selectedTerm];
    }

    dispatch(clearCourseData());
    dispatch(fetchCourses({
      requestParams: {
        selectedToggle: dateFilterKey === "PERIOD" ? "period" : "",
        ...reqBody,
        ...adminPayload,
        ...selectedDateFilter,
      }
    }));
  }

  const onClickHandlerFirst = () => {
    handlePagination( {}, searchCourseText);
  }

  const onClickHandlerNext = () => {
    handlePagination({ next: true, currentPage: pagination.currentPage }, searchCourseText);
  }

  const onClickHandlerPrev = () => {
    handlePagination({ previous: true, currentPage: pagination.currentPage }, searchCourseText);
  }

  const onItemClick = (item) => {
    const { course } = item;

    if (!course.status)  return;
    selectCourse(course);
  }

  return (
    <div className='paginator-component'>
      <Search onSearch={onSearch} />
      <h5>Courses</h5>
      <div className="course-listing with-pagination">
        <div className="menuScroller">
          {courses && courses.length === 0 && <div>No courses found...</div>}
          {courses && courses.length !== 0 && <Menu
            defaultSelectedKeys={courses && courses[0] && courses[0]["course_id"]}
            mode="inline"
          >
            {courses && _isArray(courses) && courses.map((course) => {
              const selectedMenu =
                parseInt?.(selectedCourse?.course_id) ===
                parseInt?.(course?.course_id) && !selectedCoursesLoading;
              return (
                <Menu.Item
                  onClick={({ ...params }) => onItemClick({ ...params, course })}
                  key={course.course_id}
                  className={selectedMenu ? "selected-menu" : "normal-menu"}
                  icon={<NotificationOutlined style={{marginLeft: '-20px'}}/>}
                  disabled={!course.status}
                >
                  <p className="select-menu-text">{course.course_name}</p>
                  {!course.status && (
                    <Tooltip title="No permission to view" placement='topRight'>
                      <LockOutlined
                        style={{color: '#EB5757'}}
                        className="select-disable-icon"
                      />
                    </Tooltip>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>}
        </div>
      </div>
      {courses && courses.length !== 0 && <div className='custom-paginate-group'>
        <Button disabled={pagination?.currentPage === 1} onClick={onClickHandlerFirst} >First</Button>
        <Button disabled={!pagination?.previous} onClick={onClickHandlerPrev}>Prev</Button>
        <Button disabled={!pagination?.next} onClick={onClickHandlerNext}>Next</Button>
      </div>}
    </div>
  )
}

export default CustomPaginator;