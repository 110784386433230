import React, { useEffect, useState } from "react";
import { Button, Card, Space, Typography } from "antd";
import { setBreadcrumb, setMenuSelected } from "../../../actions/dashboardManagement/layout.action";
import menuData from "../../layout/dashboardManagement/menuData";
import { useDispatch } from "react-redux";
import AddUserPopUpModal from "./addUserPopupModal";
import UserManagementTableView from "./components/userManagementTableView";
import { fetchAddUserPopupAvailableAccounts, getAccountData } from "../../../actions/userManagement/userManagement.action";
import CourseAccessSideDrawer from "./components/sideDrawer/courseAccessSideDrawer";

const UserManagementView = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const [isAddUserPopupVisible, setIsAddUserPopupVisible] = useState(false);
  const [sideDrawerEnabled, setEnableSideDrawer] = useState(false);
  const [selectedUsersArray, setSelectedUsersArray] = useState([]);

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.manageUser.key,
      section: menuData.manageUser.name,
    },
  ];

  useEffect(() => {
    dispatch(setMenuSelected(menuData.manageUser.key));
    dispatch(setBreadcrumb(breadcrumbItems));
    dispatch(getAccountData());
    dispatch(fetchAddUserPopupAvailableAccounts());
  }, []);

  const RenderHeaderComponent = () => {
    return (
      <div className="userManagemetHeader">
        <Title level={3}>
          User Management
        </Title>
        <Space>
          <Button
            disabled={selectedUsersArray.length <= 1}
            onClick={() => setEnableSideDrawer(true)}
            type="primary"
            size="large"
          >
            Set Course Permission For All
          </Button>
          <Button
            onClick={() => setIsAddUserPopupVisible(true)}
            type="primary"
            size="large"
          >
            Add User
          </Button>
        </Space>
      </div>
    )
  }

  return (
    <div className="in-container userManagement">
      <AddUserPopUpModal
        isModalVisible={isAddUserPopupVisible}
        setIsModalVisible={setIsAddUserPopupVisible}
      />
      <RenderHeaderComponent />
      <Card className="customCard userManagementCard">
        <UserManagementTableView
          setEnableSideDrawer={setEnableSideDrawer}
          setSelectedUsersArray={setSelectedUsersArray}
          selectedUsersArray={selectedUsersArray}
        />
      </Card>
      <CourseAccessSideDrawer
        sideDrawerEnabled={sideDrawerEnabled}
        setEnableSideDrawer={setEnableSideDrawer}
        setSelectedUsersArray={setSelectedUsersArray}
        selectedUsersArray={selectedUsersArray}
      />
    </div>
  )
}

export default UserManagementView;
