import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const DataPrivacyManagementSettingstest = state =>
  _get(state, 'dashboardManage.dataPrivacyManagementReducer.dataPrivacy', {});

export const makeSettingsDataPrivacyManagerLoading = createSelector(
    DataPrivacyManagementSettingstest, data => _get(data, 'isLoading', false),
);

//fetch cluster enabled status
export const makeSettingsDataPrivacyManagerStatus = createSelector(
  DataPrivacyManagementSettingstest, data => _get(data, 'data.dataPrivacy',{}),
);

