import React from "react";
import { Button, Card, Col, Row } from "antd";
import { EditOutlined } from '@ant-design/icons';

const DeveloperKeyCard = ({ clientId, clientSecret, setPopupModalVisible }) => {

    return (
        <Card style={{ width: "100%" }} className="userInfoCard">
            <Row >
                <Col md={24} lg={8}>
                    <p>Client ID</p>
                    <p>{clientId}</p>
                </Col>
                <Col md={24} lg={8}>
                    <p>Client Secret</p>
                    <p>{clientSecret}</p>
                </Col>
                <Col md={24} lg={8}>
                    <Row style={{ height: "100%" }} wrap={true} justify="end" align="middle">
                        <Button
                            className="copy-btn"
                            type="primary"
                            onClick={() => { setPopupModalVisible(true) }}
                            icon={<EditOutlined />}
                        >
                            Update
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default DeveloperKeyCard;
